import { RouteComponentProps, navigate, useParams } from '@reach/router';
import Mixpanel from '@smartpay/mixpanel';
import capitalize from 'lodash.capitalize';
import { FC, useCallback } from 'react';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import { APIPayload } from '../../api/types';
import Backdrop from '../../components/Modal/Backdrop';
import Modal from '../../components/Modal/Modal';
import OTPLayout from '../../components/OTP/OTPLayout';
import useAppSelector from '../../hooks/use-app-selector';
import useOnLogin from '../../hooks/use-on-login';
import { createAssociation, sendAssociateOTP } from '../../redux/auth';

import Button from '../../components/Form/Button';
import useAsyncMessage from '../../hooks/use-async-message';
import { getSsoService } from './AssociateScreen';
import styles from './AssociateScreen.module.scss';

const getErrorMessage = (errorCode: string) => {
  return (
    ERROR_MESSAGES['2FA'][errorCode] ||
    ERROR_MESSAGES.PIN[errorCode] ||
    ERROR_MESSAGES.SHARED.unknown
  );
};

const AssociateOTPScreen: FC<RouteComponentProps> = () => {
  const dispatch = useAppDispatch();
  const service = getSsoService(useParams().service);
  const serviceName = capitalize(service);
  const ssoToken = useAppSelector((state) => state.auth.ssoToken);
  const sessionIdWithSignature = useAppSelector(
    (state) => state.misc.sessionIdWithSignature
  );
  const associateAttemptId = useAppSelector(
    (state) => state.auth.associateAttemptId
  );

  const { shouldDisplayMessage, onMessage, onDismiss } = useAsyncMessage();
  const { onLogin } = useOnLogin({
    onFailure: (errorCode) => {
      navigate(`/${sessionIdWithSignature}`, {
        state: { errorCode },
        replace: true,
      });
    },
  });

  const onResendOtp = useCallback(
    (method) =>
      ssoToken && dispatch(sendAssociateOTP({ associateAttemptId, method })),
    [associateAttemptId, dispatch, ssoToken]
  );

  const onSubmit = useCallback(
    async (otpSecret: string) => {
      Mixpanel.trackAction({
        action: 'Click',
        itemName: 'Submit',
      });

      if (otpSecret) {
        const createAssociationAction = await dispatch(
          createAssociation({
            associateAttemptId,
            otpSecret,
          })
        );

        if (createAssociation.fulfilled.match(createAssociationAction)) {
          await onMessage();
          await onLogin(createAssociationAction.payload);
        } else {
          throw new Error(
            getErrorMessage(
              (createAssociationAction.payload as APIPayload)?.errorCode
            ),
            { cause: (createAssociationAction.payload as APIPayload)?.details }
          );
        }
      }
    },
    [associateAttemptId, dispatch, onLogin, onMessage]
  );

  return (
    <>
      <OTPLayout onSubmit={onSubmit} onResendOtp={onResendOtp} />
      {shouldDisplayMessage && (
        <>
          <Backdrop />
          <Modal>
            <div className={styles.modal}>
              <h5 className={styles.title}>連携が完了しました</h5>
              <p className={styles.message}>
                {`${serviceName}アカウントでログインできるようになりました。`}
              </p>
              <div className={styles.action}>
                <Button
                  id="btn_submit"
                  type="button"
                  label="OK"
                  onClick={onDismiss}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default AssociateOTPScreen;
