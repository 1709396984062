import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import Mixpanel from '@smartpay/mixpanel';
import { AxiosResponse } from 'axios';
import LoginAPI, {
  ChallengingLoginResponse,
  CompletePasswordResetSessionParams,
  LoginParams,
  LoginWithOtpParams,
  ResendPasswordResetSessionParams,
  SendLoginOtpParams,
  SsoAssociateResponse,
  SsoParams,
  SuccessfulLoginResponse,
} from '../api/login';
import SignupAPI, {
  CompleteSignupParams,
  CreateAssociateAttemptParams,
  CreateAssociationParams,
  CreateSignupSessionParams,
  ResendAssociateOTPParams,
  ResendSignupOTPParams,
} from '../api/signup';
import SmartpayCoAPI from '../api/smartpay-co';
import browserId from '../utils/browser-id';
import ldStore from '../utils/launch-darkly';
import { getCheckoutSession } from './good';

export const initialState = {
  email: '',
  password: '',
  newPassword: '',
  phone: '',
  phoneLast4: '',
  testOTPSecret: '',
  signupId: '',
  loginAttemptId: '',
  ssoToken: '',
  associateAttemptId: '',
  accessToken: '',
  passwordResetId: '',
  anonymousId: '',
  isTestAccount: false,
};

type State = typeof initialState;
export type AuthPairs = Partial<State>;

export const parseJWT = (token: string) =>
  JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());

export const getIsTestAccount = (accessToken: string) =>
  parseJWT(accessToken).test;

const setMixPanelUserProps = ({
  anonymousId,
  isTestAccount,
}: {
  anonymousId: string;
  isTestAccount?: boolean;
}) => {
  if (anonymousId) {
    Mixpanel.setUserID(anonymousId);

    if (isTestAccount !== undefined) {
      Mixpanel.setUserProperties({
        'Is Test User': isTestAccount,
      });
    }
  }
};

const setSentryUserProps = ({ anonymousId }: { anonymousId: string }) => {
  if (anonymousId) {
    Sentry.setUser({ id: anonymousId });
  }
};

const setLaunchDarklyUserProps = ({ anonymousId }: { anonymousId: string }) => {
  if (anonymousId) {
    ldStore.identifyUser({
      kind: 'user',
      key: anonymousId,
      custom: {
        browserId,
      },
    });
  }
};

const setVendorUserPropsFromResponse = (response: AxiosResponse) => {
  const anonymousId = response.headers['smartpay-anonymous-id'];
  const isTestAccount = response.headers['smartpay-test'] === 'true';

  setMixPanelUserProps({ anonymousId, isTestAccount });
  setSentryUserProps({ anonymousId });
  setLaunchDarklyUserProps({ anonymousId });
};

export const createSignupSession = createAsyncThunk(
  'auth/createSignupSession',
  async (params: CreateSignupSessionParams, { rejectWithValue }) => {
    try {
      const response = await SignupAPI.createSignupSession(params);

      return {
        signupId: response.data.id,
        isTestAccount: response.headers['smartpay-test'] === 'true',
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const checkEmailValidity = createAsyncThunk(
  'auth/checkEmailValidity',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await SmartpayCoAPI.checkEmailValidity({ email });

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePhone = createAsyncThunk(
  'auth/updatePhone',
  async (
    { signupId, phone }: { signupId: string; phone: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await SignupAPI.updatePhone({
        signupId,
        phone: `+81${phone.startsWith('0') ? phone.slice(1) : phone.slice(2)}`,
        method: 'text',
      });

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const completeSignup = createAsyncThunk(
  'auth/completeSignup',
  async (params: CompleteSignupParams, { rejectWithValue }) => {
    try {
      const response = await SignupAPI.completeSignup(params);

      setVendorUserPropsFromResponse(response);

      return {
        accessToken: response.data.accessToken,
        anonymousId: response.headers['smartpay-anonymous-id'],
        isTestAccount: getIsTestAccount(response.data.accessToken),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const sendSingupOTP = createAsyncThunk(
  'auth/sendOTP',
  async (params: ResendSignupOTPParams) => {
    const response = await SignupAPI.sendSingupOTP(params);

    return response.data;
  }
);

export const loginAttempt = createAsyncThunk(
  'auth/loginAttempt',
  async ({ email, password }: LoginParams, { rejectWithValue }) => {
    try {
      const response = await LoginAPI.loginAttempt({
        email,
        password,
        uniqueId: browserId,
      });

      setVendorUserPropsFromResponse(response);

      if ((response.data as SuccessfulLoginResponse).accessToken) {
        const resp = response.data as SuccessfulLoginResponse;

        return {
          accessToken: resp.accessToken,
          anonymousId: response.headers['smartpay-anonymous-id'],
          isTestAccount: getIsTestAccount(resp.accessToken),
          loginAttemptId: '',
        };
      }

      if ((response.data as ChallengingLoginResponse).id) {
        const resp = response.data as ChallengingLoginResponse;

        return {
          accessToken: '',
          anonymousId: '',
          isTestAccount: undefined,
          loginAttemptId: resp.id,
        };
      }

      throw new Error('Unexpected API response');
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const loginWithOtp = createAsyncThunk(
  'auth/loginWithOTP',
  async (params: LoginWithOtpParams, { rejectWithValue }) => {
    try {
      const response = await LoginAPI.loginWithOtp(params);

      return {
        accessToken: response.data.accessToken,
        anonymousId: response.headers['smartpay-anonymous-id'],
        isTestAccount: getIsTestAccount(response.data.accessToken),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const sendLoginOTP = createAsyncThunk(
  'auth/sendLoginOTP',
  async (params: SendLoginOtpParams) => {
    const response = await LoginAPI.sendLoginOTP(params);

    return {
      phoneLast4: response.data.phoneLast4,
    };
  }
);

export const ssoGoogle = createAsyncThunk(
  'auth/ssoLoginGoogle',
  async (params: SsoParams, { rejectWithValue }) => {
    try {
      const response = await LoginAPI.ssoGoogle(params);

      setVendorUserPropsFromResponse(response);

      if ((response.data as SuccessfulLoginResponse).accessToken) {
        const resp = response.data as SuccessfulLoginResponse;

        return {
          accessToken: resp.accessToken,
          anonymousId: response.headers['smartpay-anonymous-id'],
          isTestAccount: getIsTestAccount(resp.accessToken),
          loginAttemptId: '',
          ssoToken: '',
          email: '', // This is a special case that we don't have the email in the client side
        };
      }

      if ((response.data as SsoAssociateResponse).ssoToken) {
        const resp = response.data as SsoAssociateResponse;

        return {
          accessToken: '',
          anonymousId: '',
          isTestAccount: undefined,
          loginAttemptId: '',
          ssoToken: resp.ssoToken,
          email: '',
        };
      }

      throw new Error('Unexpected API response');
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const ssoApple = createAsyncThunk(
  'auth/ssoLoginApple',
  async (params: SsoParams, { rejectWithValue }) => {
    try {
      const response = await LoginAPI.ssoApple(params);

      setVendorUserPropsFromResponse(response);

      if ((response.data as SuccessfulLoginResponse).accessToken) {
        const resp = response.data as SuccessfulLoginResponse;

        return {
          accessToken: resp.accessToken,
          anonymousId: response.headers['smartpay-anonymous-id'],
          isTestAccount: getIsTestAccount(resp.accessToken),
          loginAttemptId: '',
          ssoToken: '',
          email: '', // This is a special case that we don't have the email in the client side
        };
      }

      if ((response.data as SsoAssociateResponse).ssoToken) {
        const resp = response.data as SsoAssociateResponse;

        return {
          accessToken: '',
          anonymousId: '',
          isTestAccount: undefined,
          loginAttemptId: '',
          ssoToken: resp.ssoToken,
          email: '',
        };
      }

      throw new Error('Unexpected API response');
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const createAssociateAttempt = createAsyncThunk(
  'auth/createAssociateAttempt',
  async (params: CreateAssociateAttemptParams, { rejectWithValue }) => {
    try {
      const response = await SignupAPI.createAssociateAttempt(params);

      return {
        associateAttemptId: response.data.id,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const createAssociation = createAsyncThunk(
  'auth/createAsosciation',
  async (params: CreateAssociationParams, { rejectWithValue }) => {
    try {
      const response = await SignupAPI.createAssociation(params);

      return {
        accessToken: response.data.accessToken,
        anonymousId: response.headers['smartpay-anonymous-id'],
        isTestAccount: getIsTestAccount(response.data.accessToken),
        loginAttemptId: '',
        ssoToken: '',
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const sendAssociateOTP = createAsyncThunk(
  'auth/sendAssociateOTP',
  async (params: ResendAssociateOTPParams) => {
    const response = await SignupAPI.sendAssociateAttemptOTP(params);

    return {
      phoneLast4: response.data.phoneLast4,
    };
  }
);

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { rejectWithValue }) => {
    try {
      const response = await LoginAPI.refreshToken();

      setVendorUserPropsFromResponse(response);

      return {
        accessToken: response.data.accessToken,
        anonymousId: response.headers['smartpay-anonymous-id'],
        isTestAccount: getIsTestAccount(response.data.accessToken),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const createPasswordResetSession = createAsyncThunk(
  'auth/createPasswordResetSession',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await LoginAPI.createPasswordResetSession({
        email,
      });

      return {
        passwordResetId: response.data.id,
        phoneLast4: response.data.phoneLast4,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const completePasswordReset = createAsyncThunk(
  'auth/completePasswordReset',
  async (params: CompletePasswordResetSessionParams, { rejectWithValue }) => {
    try {
      const response = await LoginAPI.completePasswordReset(params);

      setVendorUserPropsFromResponse(response);

      return {
        accessToken: response.data.accessToken,
        anonymousId: response.headers['smartpay-anonymous-id'],
        isTestAccount: getIsTestAccount(response.data.accessToken),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const sendPasswordResetOTP = createAsyncThunk(
  'auth/sendPasswordResetOTP',
  async (params: ResendPasswordResetSessionParams) => {
    const response = await LoginAPI.sendPasswordResetOTP(params);

    return response;
  }
);

const authSlice = createSlice({
  name: '@@checkout/auth',
  initialState,
  reducers: {
    updateAuthPairs(state, action: PayloadAction<AuthPairs>) {
      (Object.keys(action.payload) as Array<keyof typeof initialState>).forEach(
        (key: keyof typeof initialState) => {
          state[key] = action.payload[key] as never;
        }
      );

      if (action.payload.anonymousId) {
        setMixPanelUserProps({
          anonymousId: action.payload.anonymousId,
          isTestAccount: action.payload.isTestAccount,
        });
        setSentryUserProps({
          anonymousId: action.payload.anonymousId,
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createSignupSession.fulfilled, (state, action) => {
      state.signupId = action.payload.signupId;
      state.isTestAccount = action.payload.isTestAccount;
    });
    builder.addCase(sendSingupOTP.fulfilled, (state, action) => {
      state.phoneLast4 = action.payload.phoneLast4;
    });
    builder.addCase(completeSignup.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.anonymousId = action.payload.anonymousId;
      state.isTestAccount = action.payload.isTestAccount;
    });
    builder.addCase(loginAttempt.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.anonymousId = action.payload.anonymousId;
      state.isTestAccount = action.payload.isTestAccount;
      state.loginAttemptId = action.payload.loginAttemptId;
    });
    builder.addCase(sendLoginOTP.fulfilled, (state, action) => {
      state.phoneLast4 = action.payload.phoneLast4;
    });
    builder.addCase(loginWithOtp.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.anonymousId = action.payload.anonymousId;
      state.isTestAccount = action.payload.isTestAccount;
    });
    builder.addCase(ssoGoogle.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.anonymousId = action.payload.anonymousId;
      state.isTestAccount = action.payload.isTestAccount;
      state.ssoToken = action.payload.ssoToken;
      state.email = action.payload.email;
    });
    builder.addCase(ssoApple.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.anonymousId = action.payload.anonymousId;
      state.isTestAccount = action.payload.isTestAccount;
      state.ssoToken = action.payload.ssoToken;
      state.email = action.payload.email;
    });
    builder.addCase(createAssociateAttempt.fulfilled, (state, action) => {
      state.associateAttemptId = action.payload.associateAttemptId;
    });
    builder.addCase(sendAssociateOTP.fulfilled, (state, action) => {
      state.phoneLast4 = action.payload.phoneLast4;
    });
    builder.addCase(createAssociation.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.anonymousId = action.payload.anonymousId;
      state.isTestAccount = action.payload.isTestAccount;
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.anonymousId = action.payload.anonymousId;
      state.isTestAccount = action.payload.isTestAccount;
    });
    builder.addCase(createPasswordResetSession.fulfilled, (state, action) => {
      state.passwordResetId = action.payload.passwordResetId;
      state.phoneLast4 = action.payload.phoneLast4;
    });
    builder.addCase(completePasswordReset.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.isTestAccount = action.payload.isTestAccount;
    });
    builder.addCase(getCheckoutSession.fulfilled, (state, action) => {
      const { customerInfo } = action.payload.checkoutSession;

      state.phone = customerInfo.phoneNumber?.replace(/\+/g, '') || '';
    });
  },
});

export const { updateAuthPairs } = authSlice.actions;

export default authSlice.reducer;
