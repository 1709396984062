import { Link, RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import { FC, FormEvent, SyntheticEvent, useCallback, useState } from 'react';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import { APIPayload } from '../../api/types';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import { pinCodeMaskOption } from '../../components/Form/utils';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
import PasswordDescription from '../../components/PasswordDescription/PasswordDescription';
import PinResendButton from '../../components/PinResendButton';
import useAppSelector from '../../hooks/use-app-selector';
import useOnLogin from '../../hooks/use-on-login';
import {
  AuthPairs,
  completeSignup,
  sendSingupOTP,
  updateAuthPairs,
} from '../../redux/auth';
import { formatPhoneNumber, sanitizeOTP } from '../../utils';
import styles from './PinCodeScreen.module.scss';

const PinCodeScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();

  const isTokenFlow = useAppSelector((state) => state.misc.isTokenFlow);
  const signupId = useAppSelector((state) => state.auth.signupId);
  const phone = useAppSelector((state) => state.auth.phone);
  const password = useAppSelector((state) => state.auth.password);
  const ssoToken = useAppSelector((state) => state.auth.ssoToken);
  const testOTPSecret = useAppSelector((state) => state.auth.testOTPSecret);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDetails, setErrorDetails] = useState<APIPayload['details']>([]);
  const [otpSecret, setOtpSecret] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { onLogin } = useOnLogin({
    onFailure: (errorCode) =>
      setErrorMessage(
        ERROR_MESSAGES.SHARED[errorCode] || ERROR_MESSAGES.SHARED.unknown
      ),
  });

  const onUpdateAuthPairs = (pairs: AuthPairs) => {
    setErrorDetails(
      errorDetails.filter((detail) => detail.path !== 'password')
    );
    dispatch(updateAuthPairs(pairs));
  };

  const onResendOtp = useCallback(
    (method) => signupId && dispatch(sendSingupOTP({ signupId, method })),
    [dispatch, signupId]
  );

  const onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Submit',
    });

    if (password || ssoToken) {
      setIsLoading(true);

      const signupResultAction = await dispatch(
        completeSignup({
          signupId,
          password,
          otpSecret: sanitizeOTP(testOTPSecret || otpSecret),
        })
      );

      if (completeSignup.fulfilled.match(signupResultAction)) {
        await onLogin(signupResultAction.payload);
      } else if ((signupResultAction.payload as APIPayload)?.message) {
        setErrorMessage(
          ERROR_MESSAGES.PIN[
            (signupResultAction.payload as APIPayload)?.errorCode
          ] || ERROR_MESSAGES.SHARED.unknown
        );
        setErrorDetails((signupResultAction.payload as APIPayload)?.details);
      } else {
        setErrorMessage(
          ERROR_MESSAGES.PIN[
            (signupResultAction.payload as APIPayload)?.errorCode
          ] || ERROR_MESSAGES.SHARED.unknown
        );
      }
      setIsLoading(false);
    }
  };

  const onUpdateOtpSecret = (event: FormEvent<HTMLInputElement>) => {
    setErrorDetails(
      errorDetails.filter((detail) => detail.path !== 'otpSecret')
    );
    setOtpSecret(event.currentTarget.value);
  };

  return (
    <div className={cx('rwd-wrapper', isTokenFlow ? 'token-flow' : '')}>
      <aside>
        <Header />
        <MerchantHeader />
      </aside>
      <MainLayout greetings="Smartpayにようこそ！" showFooter={false}>
        <form className={styles.form} onSubmit={onSubmit}>
          {!ssoToken && (
            <div className={styles['input-box']}>
              <PasswordDescription desc="パスワードを作成してください。" />
              <TextInput
                name="password"
                type="password"
                value={password}
                aria-label="パスワード（英数字8文字以上）"
                updatePairs={onUpdateAuthPairs}
                placeholder="パスワード（英数字8文字以上）"
                autoFocus
                isPasswordVisibleDefault
                {...(errorDetails.find(
                  (detail) => detail.path === 'password'
                ) && {
                  errorMessage,
                })}
              />
            </div>
          )}

          <div className={styles['opt-secret-box']}>
            <p>
              <em>{formatPhoneNumber(phone)}</em>{' '}
              へ送信された6桁のセキュリティコードを入力してください。
            </p>
            <TextInput
              name="otpSecret"
              pattern="\d{3}-\d{3}"
              onChange={onUpdateOtpSecret}
              value={otpSecret}
              aria-label="セキュリティコード"
              className={styles['opt-secret']}
              placeholder="888-888"
              maskOption={pinCodeMaskOption}
              autoComplete="one-time-code"
              {...(errorDetails.find(
                (detail) => detail.path === 'otpSecret'
              ) && {
                errorMessage,
              })}
              {...(testOTPSecret && {
                value: testOTPSecret,
              })}
            />
            <p>
              コードを受信できませんか？{' '}
              <Link to="/phone">電話番号を再入力する</Link>
            </p>
            {errorDetails.length === 0 && errorMessage && (
              <div className={styles['error-wrapper']}>
                <p>{errorMessage}</p>
              </div>
            )}
            <PinResendButton onClick={onResendOtp} />
          </div>
          <p className={styles.tnc}>
            当社 <Link to="/terms">利用規約</Link> と{' '}
            <Link to="/privacy">プライバシーポリシー</Link> に同意して
          </p>
          <Button
            id="btn_submit"
            loading={isLoading}
            type="submit"
            label="次へ"
            disabled={
              !(
                (ssoToken || password) &&
                (testOTPSecret || (otpSecret && otpSecret.length === 7))
              )
            }
          />
        </form>
      </MainLayout>
    </div>
  );
};

export default PinCodeScreen;
